<template>
  <div>
    <Breadcrumb
      v-if="pages && pages.length"
      :key="$route.path"
      :title="pages[0]['title']"
      subPath="Pages"
      subPathURL="/pages/"
    ></Breadcrumb>
    <v-container :fluid="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <v-row>
        <v-col cols="12" md="12">
          <v-card
            style="min-height: 100vh"
            elevation="0"
            class="pl-5 pr-5 pt-10 pb-10"
            color="white"
            v-if="pages"
          >
            <div v-if="pages && pages.length && pages[0]['splash']">
              <SplashNews
                :splash="pages[0]['splash']"
                style="margin-top: -25px; margin-left: 0px"
                v-if="!pages[0]['hideSplash']"
                :height="550"
              ></SplashNews>
            </div>
            <v-row>
              <v-col class="mb-3">
                <h1
                  class="mb-4"
                  v-if="pages && pages.length"
                  style="
                    font-size: 36px;
                    line-height: 40px;
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 10px;
                  "
                >
                  {{ pages[0]["title"] }}
                </h1>

                <PostedMeta
                  :meta="meta"
                  style="font-size: 12px"
                  v-if="pages && pages.length"
                ></PostedMeta>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
                order-md="1"
                order="2"
                order-sm="2"
                class="markdown-body"
              >
                <div
                  v-if="pages && pages.length"
                  v-html="render(pages[0]['body'])"
                  @click="handleClicks"
                  class="dynamic-content"
                ></div>

                <!-- <DocumentList
                  :documents="pages[0]['documents']"
                  v-if="
                    pages &&
                    pages[0]['documents'] &&
                    pages[0]['documents'].length
                  "
                  class="mt-8"
                ></DocumentList> -->

                <div class="mb-50">&nbsp;</div>

                <DocumentTable
                  :documents="pages[0].documents"
                  :searchLabel="`Search Documents`"
                  :key="$route.path"
                  :hideSearch="true"
                  :hideFooter="true"
                  heading="Documents and Resources"
                  v-if="pages[0].documents && pages[0].documents.length"
                ></DocumentTable>

                <div
                  class="mt-8"
                  v-if="pages && pages[0]['tags'] && pages[0]['tags'].length"
                  style="font-size: 14px"
                >
                  <span v-for="(tag, index) in pages[0]['tags']" :key="index">
                    <v-chip
                      small
                      class="mr-1"
                      @click.stop.prevent="
                        $router.push(`/tags/${tag.slug}/`).catch((err) => {
                          $vuetify.goTo(0);
                        })
                      "
                    >
                      {{ tag.title }}
                    </v-chip>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { handleClicks } from "@/mixins/handleClicks";
import { renderToHtml } from "@/services/Markdown";

import _ from "lodash";
import { GET_SINGLE_PAGE_QUERY } from "@/graphql/queries/pages";

export default {
  name: "PageSingle",
  mixins: [handleClicks],
  data() {
    return {
      item: null,
      meta: null,
    };
  },
  methods: {
    render(content) {
      return renderToHtml(content);
    },
    routeToUnit(unit) {
      if (unit) {
        this.$router.push(`/units/${unit.slug}`).catch(() => {
          this.$vuetify.goTo(0);
        });
      } else {
        this.$router.push("/units/");
      }
    },
  },
  apollo: {
    pages: {
      prefetch: true,
      query: GET_SINGLE_PAGE_QUERY,
      variables() {
        return {
          slug: this.$route.params.slug,
        };
      },
      error(error) {
        this.error = JSON.stringify(error.message);
      },
      result(ApolloQueryResult) {
        if (
          ApolloQueryResult &&
          ApolloQueryResult.data &&
          ApolloQueryResult.data.pages.length > 0 === false
        ) {
          // eslint-disable-next-line no-unused-vars
          this.$router.push("/404").catch((err) => {
            console.log(err);
          });
          console.log("not found");
        } else {
          this.meta = {
            published_at: ApolloQueryResult.data.pages[0]["published_at"],
            created_at: ApolloQueryResult.data.pages[0]["created_at"],
            updated_at: ApolloQueryResult.data.pages[0]["updated_at"],
          };
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mainToc {
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  top: 115px !important;
  font-size: 13px;
}

.kicker {
  font-weight: 900;
  color: #0d4474;
  text-transform: uppercase;
  font-size: 12px;
}
</style>
