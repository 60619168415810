/* eslint-disable graphql/template-strings */
import gql from "graphql-tag";

const GET_ALL_PAGES_QUERY = gql`
  query getAllPages {
    pages(sort: "published_at:desc") {
      id
      slug
      title
      summary
      created_at
      category
      updated_at
      published_at
      tags(sort: "title:asc") {
        id
        title
        slug
        created_at
        summary
      }
      splash {
        url
        formats
      }
    }
  }
`;

const GET_SINGLE_PAGE_QUERY = gql`
  query getSinglePage($slug: String!) {
    pages(where: { slug: $slug }) {
      id
      slug
      title
      summary

      hideSplash
      created_at
      updated_at
      published_at

      tags(sort: "title:asc") {
        id
        title
        slug
        created_at
        summary
      }
      body
      category
      splash {
        url
        formats
      }
      documents {
        published_at
        updated_at
        title
        summary
        externalURL
        body
        file {
          url
          name
          ext
        }
        slug
      }
    }
  }
`;

export { GET_ALL_PAGES_QUERY, GET_SINGLE_PAGE_QUERY };
